import styled from "styled-components"
import { Flex } from "../Flex/Flex"

export const BlogPost = styled(Flex)`
  flex-direction: column;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    color: #292929;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
    font-style: normal;
    font-weight: 500;
  }

  p {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #505050;
    font-weight: 300;
    margin: 0;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    p {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
`
