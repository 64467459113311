import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import Layout from "../../components/layout"
import { BlogTitle } from "../../components/atoms/BlogTitle/BlogTitle"
import { TopBar } from "../../components/molecules/TopBar/TopBar"
import { Caption } from "../../components/atoms/Typography/Typography"
import { Gap } from "../../components/atoms/Gap/Gap"
import { NewsletterForm } from "../../components/molecules/NewsletterForm/NewsletterForm"
import "react-mailchimp-email-signup-form/dist/esm/index.css"
import { Footer } from "../../components/molecules/Footer/Footer"
import {
  BlogPost as BlogPostType,
  blogPosts,
} from "../../dataSources/blogPosts"
import ReactMarkdown from "react-markdown"
import { Flex } from "../../components/atoms/Flex/Flex"
import { BlogPost } from "../../components/atoms/BlogPost/BlogPost"
import { DynamicImage } from "../../components/atoms/DynamicImage/DynamicImage"
import { Helmet } from "react-helmet"

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 2vh;
    box-sizing: border-box;
  }
`

const BlogPostToolbar = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-end;
`

const BlogPostDate = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  flex-grow: 1;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 4px;
`
const BlogPostSocialShare = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;

  button {
    margin-right: 4px;
  }

  button:hover svg path {
    fill: #929292;
  }
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Hr = styled.hr`
  height: 1px;
  width: 900px;
  background-color: #e5e5e5;
  border: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const Section = styled.div`
  display: flex;
  padding: 5vh 0;

  @media only screen and (max-width: 768px) {
    padding: 2vh 0;
  }
`

const CenteredSection = styled(Section)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledBlogTitle = styled(BlogTitle)`
  margin-bottom: 32px;
`

const ParagraphCoverImage = styled.div`
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 32px;

  > div {
    height: auto;
  }
`

const pageId = "how-we-built-iterspace"

const HowWeBuiltIterspace = () => {
  const blogPost: BlogPostType =
    blogPosts.find((post) => post.id === pageId) || ({} as BlogPostType)

  const blogPostPicture = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "blog/how-we-built-iterspace.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 620, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <title>Luca Restagno - Blog</title>
        <meta
          name="description"
          content="How we built a SaaS with React and AWS"
        />

        <meta
          property="og:url"
          content="https://www.lucarestagno.com/blog/how-we-built-iterspace/"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Luca Restagno - Blog" />
        <meta
          property="og:description"
          content="How we built a SaaS with React and AWS"
        />
        <meta
          property="og:image"
          content="/images/iterspace_architecture.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="lucarestagno.com" />
        <meta
          property="twitter:url"
          content="https://www.lucarestagno.com/blog/how-we-built-iterspace/"
        />
        <meta name="twitter:title" content="Luca Restagno - Blog" />
        <meta
          name="twitter:description"
          content="How we built a SaaS with React and AWS"
        />
        <meta
          name="twitter:image"
          content="/images/iterspace_architecture.png"
        />
      </Helmet>
      <Container>
        <TopBar />
        <Content>
          <Section>
            <FlexCol>
              <Gap height={24} />
              <StyledBlogTitle
                emoji={blogPost?.emoji || ""}
                title={blogPost?.title || ""}
              />
              <BlogPostToolbar>
                <BlogPostDate>
                  <Caption>
                    <i>{blogPost.createdAt} • 4 min read</i>
                  </Caption>
                </BlogPostDate>
                <BlogPostSocialShare>
                  <EmailShareButton url={blogPost.url} title="Share via email">
                    <EmailIcon
                      size={32}
                      round
                      bgStyle={{ fill: "transparent" }}
                      iconFillColor="#acacac"
                    />
                  </EmailShareButton>
                  <FacebookShareButton
                    url={blogPost.url}
                    quote="Read the article about how the web product iterspace was built"
                    title="Share on Facebook"
                  >
                    <FacebookIcon
                      size={32}
                      round
                      bgStyle={{ fill: "transparent" }}
                      iconFillColor="#acacac"
                    />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={blogPost.url}
                    via="ikoichi"
                    title="Read the article about how the web product iterspace was built"
                  >
                    <TwitterIcon
                      size={32}
                      round
                      bgStyle={{ fill: "transparent" }}
                      iconFillColor="#acacac"
                    />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={blogPost.url}
                    title="Hi 👋  I suggest you reding the article about how the web product iterspace was built!"
                    style={{ marginLeft: "6px", marginRight: "4px" }}
                  >
                    <WhatsappIcon
                      size={24}
                      round
                      bgStyle={{ fill: "transparent" }}
                      iconFillColor="#acacac"
                    />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={blogPost.url}
                    title="Hi 👋  I suggest you reding the article about how the web product iterspace was built!"
                    style={{ marginLeft: "6px" }}
                  >
                    <TelegramIcon
                      size={24}
                      round
                      bgStyle={{ fill: "transparent" }}
                      iconFillColor="#acacac"
                    />
                  </TelegramShareButton>
                </BlogPostSocialShare>
              </BlogPostToolbar>

              <Gap height={8} />
              <Img
                fluid={blogPostPicture.file.childImageSharp.fluid}
                alt={blogPost.id}
              />
              <Gap height={24} />
              <BlogPost>
                {blogPost?.paragraphs.map((paragraph) => (
                  <>
                    <ReactMarkdown>{paragraph.title.trim()}</ReactMarkdown>
                    {paragraph.coverPictureUrl && (
                      <ParagraphCoverImage>
                        <DynamicImage
                          fileName={paragraph.coverPictureUrl}
                          alt={`${paragraph.title.trim()} cover image`}
                          isAnchorActive
                        />
                      </ParagraphCoverImage>
                    )}
                    <ReactMarkdown linkTarget="_blank">
                      {paragraph.content.trim()}
                    </ReactMarkdown>
                  </>
                ))}
              </BlogPost>
            </FlexCol>
          </Section>
        </Content>
        <Hr />
        <CenteredSection>
          <Gap height={32} />
          <NewsletterForm />
          <Gap height={32} />
        </CenteredSection>
        <Footer />
      </Container>
    </Layout>
  )
}

export default HowWeBuiltIterspace
